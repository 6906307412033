import { toast } from "react-toastify";
import { all, put, takeLatest, delay } from "redux-saga/effects";

//Api
import {
  downloadExcelReportFn,
  downloadCampaignPdfReportFn,
  getCampaignSummaryData,
  initiateMavinReportGeneration,
} from "../../apis/CampaignReportAPI";

//Constants
import { CampaignReport } from "../../constants/action-constants/CampaignReportActionConstants";

//Utils
import { getErrorMessage } from "../../utils/util";
import { saveFile } from "../../common-utils/file-utils/FileUtils";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { ReportConstants } from "../../constants/GeneralConstants";

/**
 * Campaign overview
 * overview of a particular campaign
 * needs campaign id
 */
export function* getCampaignOverview(action) {
  try {
    const { id } = action.payload;
    const data = yield getCampaignSummaryData(id);

    //dispatching action
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(err);

    /* istanbul ignore next */
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_FAILURE,
      payload: err,
    });
  }
}

export function* generateReport(action) {
  try {
    const { campaignId } = action.payload;

    // Initiate the process for generating the report..
    yield initiateMavinReportGeneration(campaignId);

    // Fetch the summary to check if the report is generated
    let data = yield getCampaignSummaryData(campaignId);

    // We pool till the report is generated..
    while (data.summary.reportStatus !== "GENERATED") {
      data = yield getCampaignSummaryData(campaignId);
      yield delay(5000);
    }

    yield put({
      type: CampaignReport.GENERATE_MAVIN_REPORT_SUCCESS,
    });

    // Once the report is generated we update the campaign summary details to reflect in the UI
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    yield put({
      type: CampaignReport.GENERATE_MAVIN_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* downloadExcelReport(action) {
  try {
    const { campaignId, isMantaraySpecific } = action.payload;

    //making ajax call
    const data = yield downloadExcelReportFn(campaignId, isMantaraySpecific);
    saveFile(ReportConstants.CAMPAIGN_XLS_REPORT, data);
    // TODO : implement reducer for this action
    yield put({
      type: CampaignReport.DOWNLOAD_XLS_REPORT_SUCCESS,
      data: data,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignReport.DOWNLOAD_XLS_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

export function* downloadCampaignPdfReport(action) {
  try {
    const { campaignId } = action.payload;
    const data = yield downloadCampaignPdfReportFn(campaignId);
    saveFile(ReportConstants.CAMPAIGN_PDF_REPORT, data);
    yield put({
      type: CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

export default function* root() {
  yield all([
    takeLatest(CampaignReport.CAMPAIGN_OVERVIEW, getCampaignOverview),
    takeLatest(CampaignReport.GENERATE_MAVIN_REPORT, generateReport),
    takeLatest(CampaignReport.DOWNLOAD_XLS_REPORT, downloadExcelReport),
    takeLatest(
      CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT,
      downloadCampaignPdfReport
    ),
  ]);
}
