import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { selectExploreSite } from "../../../actions/map-view/media-sites/ExploreSitesSelectionActions";

// Utils & constants
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import Spinner from "../../../components/spinner/Spinner";
import { InfoCell, ImpressionsCell, LtsCell } from "../../../components/campaign-media-table-row/MediaRow";
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";

// Constants
function getInventoryMediaTableHeaders(campaignId) {
  const tableHeaders = [
    {
      title: {
        displayName: "Site Name",
        className: "col-6",
      },
      subTitle: {
        displayName: "Media type, Dimensions, Lighting, Region",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Impressions",
        className: "col-2 text-right",
      },
      subTitle: {
        displayName: "Monthly",
        className: "sub-text text-right",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "col-2 text-right",
      },
      subTitle: {
        displayName: "Sites",
        className: "sub-text text-right",
      },
    },
  ];

  // Adding additional header to table for "addMediaToCampaign" button
  if (campaignId) {
    tableHeaders.push({
      title: {
        displayName: "",
        className: "text-right",
      },
    });
  }

  return tableHeaders;
}

function ActionButtonCell({ campaignId, campaignMediaInfo, isSiteAdded, dispatchFunction, isLoading, isOrgMedia }) {
  //Dispatch
  const dispatch = useDispatch();

  const { campaignPlanning, cityAnalyser } = UrlKeys;
  // plan and city-analyser key will not present in campaign analyser page url
  // so this will return true for campaign planner and city analyser page
  // if the plan is present in url then its is from campaign planning page
  const isDisabled = useUrlKeyCheck(campaignPlanning, cityAnalyser);

  const { price, mediaId, pricing, cityId } = campaignMediaInfo;
  const campaignMedia = [
    {
      price,
      priceFactor: 1,
      mediaId,
      priceMode: "FIXED",
      cityId,
      sellerPrice: pricing?.price,
      mediaTypeStr: isOrgMedia ? "orgMedia" : "sellerMedia",
    },
  ];
  const buttonLabel = isSiteAdded ? "Added" : "Add";
  const buttonClassName = isSiteAdded ? "btn-outline-success" : "btn-outline-primary";

  return (
    <td className="align-middle">
      <button
        className={`btn ${buttonClassName} add-button shadow-none`}
        onClick={() => dispatch(dispatchFunction(campaignId, campaignMedia))}
        disabled={isLoading || isSiteAdded || !isDisabled}
      >
        {isSiteAdded && <i className="fa fa-check mx-1"></i>}
        {buttonLabel}
        {isLoading && <Spinner className="ml-2 spinner-border-sm" />}
      </button>
    </td>
  );
}

function ExploreSitesTableRow({ media, campaignId, isOrgMedia }) {
  const { mediaId, sellerId } = media;

  const isLoading = useSelector((state) => state.exploreSitesSelection.selectedSitesLoadingMap[mediaId]);
  const mediaInfo = useSelector((state) => state.mapViewMediaSites.filteredMediaSites);

  const isSiteAdded = mediaInfo.some((mediaList) => mediaList.mediaId === mediaId);

  // Redirect Url
  const sellerMediaDetailPageUrl = RedirectTo.sellerMediaDetailPageUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);

  return (
    <tr>
      <InfoCell mediaElementStyle={"p-2"} media={media} redirectUrl={sellerMediaDetailPageUrl} />

      <ImpressionsCell impressionsElementStyle={"p-2 text-right align-middle"} media={media} />

      <LtsCell ltsElementClass={"p-2 text-right align-middle"} media={media} />

      {campaignId && (
        <ActionButtonCell
          campaignId={campaignId}
          campaignMediaInfo={media}
          isSiteAdded={isSiteAdded}
          dispatchFunction={selectExploreSite}
          isLoading={isLoading}
          isOrgMedia={isOrgMedia}
        />
      )}
    </tr>
  );
}

function ExploreSitesTable({ mediaList, pagination, loadCityMedia, isOrgMedia }) {
  const { id: campaignId } = useParams();

  const { campaignPlanning, cityAnalyser } = UrlKeys;
  // plan and city-analyser key will not present in campaign analyser page url
  // so this will return true for campaign planner and city analyser page
  // if the plan is present in url then its is from campaign planning page
  const isDisabled = useUrlKeyCheck(campaignPlanning, cityAnalyser);

  if (mediaList.length < 1) {
    return null;
  }

  const tableHeaders = getInventoryMediaTableHeaders(campaignId);

  const { totalCount, pageNo, pageSize } = pagination;

  return (
    <div className="table-responsive" disabled={!isDisabled}>
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        <tbody>
          {mediaList.map((media) => (
            <ExploreSitesTableRow media={media} key={media.id} campaignId={campaignId} isOrgMedia={isOrgMedia} />
          ))}
        </tbody>
      </table>

      <Pagination totalCount={totalCount} pageNo={pageNo} pageSize={pageSize} onClick={loadCityMedia} />
    </div>
  );
}

export default ExploreSitesTable;
