import { useDispatch, useSelector } from "react-redux";

//Actions
import {
  downloadExcelReport,
  downloadCampaignPdfReport,
} from "../../../actions/campaign-report/CampaignReportActions";

//Components
import Spinner from "../../../components/spinner/Spinner";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Page Components
function DownloadReport({ campaignId, isMantaraySpecific }) {
  //Dispatch
  const dispatch = useDispatch();

  //Selector
  const downloadingXlsReport = useSelector(
    (state) => state.campaignReport.downloadXlsReportLoader
  );

  const downloadingPdfReport = useSelector(
    (state) => state.campaignReport.downloadCampaignPdfReportLoader
  );

  if (downloadingPdfReport || downloadingXlsReport) {
    return <Spinner className="spinner-border mr-3" />;
  }

  return (
    <Dropdown style="mt-0" buttonName="Download Campaign Report">
      <div className="dropdown-menu dropdown-menu-right">
        <button
          className="dropdown-item"
          onClick={() =>
            dispatch(downloadExcelReport(campaignId, isMantaraySpecific))
          }
        >
          Xls
        </button>
        <button
          className="dropdown-item"
          onClick={() => dispatch(downloadCampaignPdfReport(campaignId))}
        >
          Pdf
        </button>
      </div>
    </Dropdown>
  );
}

function ReportSection({ campaign, isMantaraySpecific = false }) {
  //Selector
  const reportGenerating = useSelector(
    (state) => state.campaignReport.generateReportLoading
  );

  const campaignId = campaign.id;

  //Generate Report
  if (reportGenerating) {
    return (
      <AlertMessage
        className="mb-0 py-2"
        spinnerClassName="spinner-border-sm"
        textMessage="Your report is being generated. We will update here once it is generated."
      />
    );
  }

  //Download Report
  return (
    <DownloadReport
      campaignId={campaignId}
      isMantaraySpecific={isMantaraySpecific}
    />
  );
}

export default ReportSection;
