import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Constants and Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RediectUtil";
import {
  toLocaleString,
  formatText,
} from "../../common-utils/string-utils/StringUtils";
import {
  getIsSubscriptionActive,
  maskCharacter,
} from "../../common-utils/subscription-utils/SubscriptionUtil";
import { getMediaOts, getMediaLtsRating } from "../../utils/mavinMetrics";
import {
  getFrequencyValue,
  getModifiedPopulation,
  getReachPercentage,
  getReachValueAsString,
} from "../../utils/ReachAndFrequencyUtils";
import { checkIsSellerUser } from "../../utils/SellerUtils";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../constants/GeneralConstants";

// CSS
import "./MediaRow.scss";

export function ImpressionsCell({ impressionsElementStyle = "", media }) {
  const { ots, tgOts } = getMediaOts(media) || {};

  // Checking Org is Subscribed or Not and is Org Type is Seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();

  const finalImpressions =
    !isSubscribed && isSeller
      ? maskCharacter(ots)
      : formatText(toLocaleString(ots ? ots : "NA"));

  const finalTgImpressions =
    !isSubscribed && isSeller
      ? maskCharacter(tgOts)
      : formatText(toLocaleString(tgOts ? tgOts : "NA"));

  return (
    <td className={impressionsElementStyle}>
      <p className="mb-2 mt-0">{finalTgImpressions}</p>
      <p className="mb-2 mt-0">{finalImpressions}</p>
    </td>
  );
}

export function LtsCell({ ltsElementClass, media }) {
  const ltsSummary = media.ltsSummary || {};
  const ltsRating = getMediaLtsRating(ltsSummary);
  return <td className={ltsElementClass}>{formatText(ltsRating)}</td>;
}

export function SelectionCell({
  mediaId,
  siteSelected,
  selectSite,
  unSelectSite,
}) {
  const dispatch = useDispatch();

  function toggleMediaSite(e) {
    const action = e.target.checked ? selectSite : unSelectSite;
    dispatch(action(mediaId));
  }

  return (
    <input
      type="checkbox"
      checked={!!siteSelected}
      onChange={toggleMediaSite}
    />
  );
}

export function DefaultImage() {
  return (
    <i className="far fa-images fa-4x media__default-image text-primary"></i>
  );
}

export function MediaImage({ media }) {
  let imageUrl;
  if (media.fileItems && media.fileItems.length) {
    imageUrl = media.fileItems[0].thumbnailUrl;
  }

  if (!imageUrl) {
    return <DefaultImage />;
  }

  return (
    <div className="media">
      <img className="media__site-image" src={imageUrl} alt="thumbnail-image" />
    </div>
  );
}

function MediaLink({ media = {}, redirectUrl }) {
  return (
    <Link to={constructRedirectPath(redirectUrl)} target="_blank">
      {formatText(media.title)}
    </Link>
  );
}

export function MediaMetaData({ media = {} }) {
  const { pricing = {} } = media;
  const { price = 0 } = pricing;
  const { mantaraySpecific } = UrlKeys;
  const isMantaraySpecific = useUrlKeyCheck(mantaraySpecific);
  const { type, height, width, litStatus, region } = media;
  return (
    <div>
      <span className="sub-text">
        {type} | {height} ft X {width} ft | {litStatus}
      </span>
      <span className="sub-text">
        {region}
        {isMantaraySpecific && (
          <>
            | <span className="font-weight-bold"> Cost :</span>
            {Math.floor(price)}
          </>
        )}
      </span>
    </div>
  );
}

export function InfoCell({ mediaElementStyle = "", media, redirectUrl }) {
  return (
    <td className={mediaElementStyle}>
      <div className="d-flex align-items-center">
        <MediaImage media={media} />
        <div className="pl-2">
          <MediaLink media={media} redirectUrl={redirectUrl} />
          <MediaMetaData media={media} />
        </div>
      </div>
    </td>
  );
}

export function ActionButtonCell({
  id,
  selectedElement,
  selectElementFn,
  unSelectElementFn,
}) {
  //Dispatch
  const dispatch = useDispatch();

  const dispatchFunction = selectedElement
    ? unSelectElementFn
    : selectElementFn;
  const buttonLabel = selectedElement ? "Added" : "Add";

  return (
    <td className="align-middle">
      <button
        className={`btn ${
          selectedElement ? "btn-outline-success" : "btn-outline-primary"
        } add-button shadow-none`}
        onClick={() => dispatch(dispatchFunction(id))}
      >
        {selectedElement ? <i className="fa fa-check mx-1"></i> : ""}
        {buttonLabel}
      </button>
    </td>
  );
}

export function ReachCell({
  reachElementStyle = "",
  reachFrequency = {},
  cityPopulation,
  showPopulation,
}) {
  const { reach, tgReach } = getReachValueAsString(reachFrequency);
  const reachPercentage = getReachPercentage(cityPopulation, reachFrequency);
  const { tgReachPercent } = reachFrequency || {};
  // Checking Org is Subscribed or Not and is Org Type is Seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();

  // If not subscribed and is seller showing * otherwise
  // showing reach percent with two decimals
  const finalTgreachPercent =
    !isSubscribed && isSeller
      ? `(*%)`
      : tgReachPercent
      ? tgReachPercent.toFixed(2)
      : "";

  return (
    <td className={reachElementStyle}>
      <p className="mb-2 mt-0">
        {formatText(tgReach)}{" "}
        {finalTgreachPercent ? `(${finalTgreachPercent}%)` : ""}
      </p>
      <p className="mb-2 mt-0">
        {formatText(reach)} {reachPercentage}
      </p>

      {showPopulation && (
        <span className="sub-text">
          {getModifiedPopulation(cityPopulation)}
        </span>
      )}
    </td>
  );
}

export function FrequencyCell({
  frequencyElementClass = "",
  reachFrequency = {},
}) {
  const frequencyValue = getFrequencyValue(reachFrequency);
  return (
    <td className={frequencyElementClass}>
      <p className="my-0">{frequencyValue}</p>
    </td>
  );
}
