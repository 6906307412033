import { CampaignReport } from "../../constants/action-constants/CampaignReportActionConstants";

export const getCampaignOverview = (id) => ({
  type: CampaignReport.CAMPAIGN_OVERVIEW,
  payload: { id },
});

export const generateMavinReport = (campaignId) => ({
  type: CampaignReport.GENERATE_MAVIN_REPORT,
  payload: { campaignId },
});

export const downloadExcelReport = (campaignId, isMantaraySpecific) => ({
  type: CampaignReport.DOWNLOAD_XLS_REPORT,
  payload: { campaignId, isMantaraySpecific },
});

export const downloadCampaignPdfReport = (campaignId) => ({
  type: CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT,
  payload: { campaignId },
});
