import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Actions
import {
  getCampaignMediaByCity,
  updateCampaignMediaDuration,
} from "../../../../actions/campaign-media/CampaignMediaActions";
import { getCityOverview } from "../../../../actions/campaign-report/CampaignCityReportActions";
import { openConfirmationForm } from "../../../../actions/ConfirmationFormActions";
import { selectExploreSite } from "../../../../actions/map-view/media-sites/ExploreSitesSelectionActions";
import { getMapViewExploreSites } from "../../../../actions/map-view/media-sites/ExploreSitesActions";
import { removeMediaSite } from "../../../../actions/map-view/media-sites/MediaSitesActions";

// Constants and Utils
import { defaultPagination } from "../../../../constants/UrlConstants";
import {
  FormDataTargets,
  LocalStorageKeys,
} from "../../../../constants/GeneralConstants";
import { getItem } from "../../../../utils/localstorage";
import { SearchFilterAndSortMediaSection } from "../../../../pages/map-view/media-sites/MediaSitesUtils";
import { getModifiedPopulation } from "../../../../utils/ReachAndFrequencyUtils";
import { constructRedirectPath } from "../../../../utils/redirect-utils/RediectUtil";

// Urls
import { RedirectTo } from "../../../../urls/PageLinksURL";

// Component
import Spinner from "../../../../components/spinner/Spinner";
import PageHeader from "../../../../mavin/components/page-header/PageHeader";
import CampaignSidebar from "../../../../components/campaign-sidebar/CampaignSidebar";
import TableHeaders from "../../../../components/table/TableHeaders";
import {
  FrequencyCell,
  ImpressionsCell,
  InfoCell,
  LtsCell,
  ReachCell,
} from "../../../../components/campaign-media-table-row/MediaRow";
import { DurationCell } from "../../../../components/campaign-table-row/CampaignRowCells";
import { CampaignCityPerformance } from "../../../../mavin/components/campaign-city-performance/CampaignCityPerformance";
import ConfirmationForm from "../../../../components/confirmation-form/ConfirmationForm";

//  Page Constants
const otsHeaderInfo = [
  {
    displayText: "Tg Impressions",
  },
  {
    displayText: "Total Impressions",
  },
];

const reachHeaderInfo = [
  {
    displayText: "Tg Reach (%)",
  },
  {
    displayText: "Total Reach (%)",
  },
];

const tableHeaders = (cityPopulation) => [
  {
    title: {
      displayName: "Site Name",
      className: "col-4",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "",
    },
  },
  {
    title: {
      displayName: "Start and end date",
      className: "col-2 text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "text-right",
    },
  },
  {
    element: <OtsAndReachHeader headerInfo={otsHeaderInfo} />,
  },
  {
    element: (
      <OtsAndReachHeader
        headerInfo={reachHeaderInfo}
        subText={`Population: ${getModifiedPopulation(cityPopulation)}`}
      />
    ),
  },
  {
    title: {
      displayName: "Frequency",
      className: "col-1 text-right",
    },
  },
  {
    title: {
      displayName: "LTS",
      className: "col-1 text-center",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-1 text-center",
    },
  },
];

function OtsAndReachHeader({ headerInfo, subText }) {
  return (
    <th className="text-right">
      {headerInfo.map((eachHeaderInfo, i) => (
        <React.Fragment key={i}>
          <p className="mb-2 mt-0">{eachHeaderInfo.displayText}</p>
        </React.Fragment>
      ))}

      <p className="sub-text mb-0">{subText}</p>
    </th>
  );
}

function findMediaSiteById(mediaSites, mediaId) {
  return mediaSites.find((mediaSite) => mediaSite.mediaId === mediaId);
}

export function MediaSelectionButton({
  campaignId,
  campaignMediaInfo,
  setSelectedMediaSites,
  campaignMediaSites,
}) {
  // dipsatch
  const dispatch = useDispatch();

  // useState
  const [isSiteAdded, setIsSiteAdded] = useState(false);

  const { mediaId, pricing, cityId, duration } = campaignMediaInfo;
  const price = (duration * (pricing?.price ?? 0)) / 30;

  const campaignMediaBean = {
    price,
    priceFactor: 1,
    mediaId,
    priceMode: "FIXED",
    cityId,
    mediaTypeStr: "orgMedia",
  };

  // is campgain media
  const isCampaigMedia = campaignMediaSites.some(
    (mediaList) => mediaList.mediaId === mediaId
  );

  const selectedMediaSite =
    campaignMediaSites.length > 0 &&
    findMediaSiteById(campaignMediaSites, mediaId);

  // to  unselect or remove media site
  function unSelectMedia() {
    setSelectedMediaSites((prevState) =>
      prevState.filter((media) => media.mediaId !== mediaId)
    );
    setIsSiteAdded(false);
    if (isCampaigMedia) {
      dispatch(
        openConfirmationForm({
          campaignId,
          campaignMediaInfo: selectedMediaSite,
        })
      );
    }
  }

  // to selecte media site
  function selectMedia() {
    setIsSiteAdded(true);
    setSelectedMediaSites((prev) => [...prev, campaignMediaBean]);
  }

  const btnClassName = isCampaigMedia
    ? "btn-outline-danger"
    : "btn-outline-success";

  if (isSiteAdded || isCampaigMedia) {
    return (
      <td className="col-1 align-middle">
        <button
          type="button"
          className={`btn added-button shadow-none ${btnClassName}`}
          onClick={unSelectMedia}
          data-toggle="modal"
          data-target={`#${FormDataTargets.confirmationForm}`}
        >
          {isCampaigMedia && <b>Remove</b>}
          {!isCampaigMedia && (
            <>
              <i className="fa fa-check"></i>
              <b> selected</b>
            </>
          )}
        </button>
      </td>
    );
  }
  return (
    <td className="col-1 align-middle">
      <button
        type="button"
        className="btn add-button shadow-none btn-outline-primary"
        onClick={selectMedia}
      >
        <b>Select</b>
      </button>
    </td>
  );
}

function PlanCityViewMediaRow({
  media,
  cityPopulation,
  setSelectedMediaSites,
  campaignMediaSites,
}) {
  // dispatch
  const dispatch = useDispatch();

  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;
  // media id
  const { id: mediaId } = media;

  //campaign
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const {
    startTimestamp: campaignStartTimestamp,
    endTimestamp: campaignEndTimestamp,
    id: campaignId,
  } = campaign;
  const { duration: citySummaryDuration } = citySummary;

  const selectedMediaSite = findMediaSiteById(campaignMediaSites, mediaId);

  media.isSelected = selectedMediaSite ? true : false;
  media.startTimestamp = campaignStartTimestamp;
  media.endTimestamp = campaignEndTimestamp;
  media.duration = citySummaryDuration;

  const mediaInfo = selectedMediaSite || media;
  const { startTimestamp, endTimestamp, duration, reachFrequency, id } =
    mediaInfo;

  const dateObj = { startTimestamp, endTimestamp };

  if (!media || Object.keys(media).length === 0) {
    return null;
  }

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignMediaDuration(selectedDate, mediaInfo));
  }

  // Redirect Url
  const campaignMediaViewPageUrl = selectedMediaSite
    ? RedirectTo.campaignMediaViewPageUrl
        .replace(":campaignId", campaignId)
        .replace(":mediaId", id)
    : RedirectTo.orgMediaDetailPageUrl
        .replace(":orgId", orgId)
        .replace(":mediaId", mediaId);

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={mediaInfo}
        redirectUrl={campaignMediaViewPageUrl}
      />
      <DurationCell
        dateObj={dateObj}
        durationElementStyle={"p-2 text-right align-middle"}
        onDateSelect={onDateSelect}
        duration={duration}
      />
      <ImpressionsCell
        impressionsElementStyle={"p-2 text-right align-middle"}
        media={mediaInfo}
      />
      <ReachCell
        reachElementStyle={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
        cityPopulation={cityPopulation}
        showPopulation={false}
      />
      <FrequencyCell
        frequencyElementClass={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
      />
      <LtsCell ltsElementClass={"p-2 text-center align-middle"} media={media} />
      <MediaSelectionButton
        campaignId={campaignId}
        campaignMediaInfo={mediaInfo}
        setSelectedMediaSites={setSelectedMediaSites}
        campaignMediaSites={campaignMediaSites}
      />
    </tr>
  );
}

function PlanCityViewMediaBody({
  mediaList,
  cityPopulation,
  setSelectedMediaSites,
  campaignMediaSites,
}) {
  if (mediaList.length === 0) {
    return (
      <tr>
        <td colSpan={7} className="text-center mt-3 font-italic">
          No Sites are available
        </td>
      </tr>
    );
  }
  return (
    <>
      {/* Table rows */}
      {mediaList.map((media) => (
        <PlanCityViewMediaRow
          media={media}
          key={media.id}
          cityPopulation={cityPopulation}
          setSelectedMediaSites={setSelectedMediaSites}
          campaignMediaSites={campaignMediaSites}
        />
      ))}
    </>
  );
}

function PlanCityViewMediaTable({
  mediaList,
  cityPopulation,
  setSelectedMediaSites,
  campaignMediaSites,
}) {
  const loading = useSelector((state) => state.mapViewExploreSites.loading);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        {/* Table header */}
        <TableHeaders
          tableHeaders={tableHeaders(cityPopulation)}
          headerClass={"thead"}
        />

        <tbody>
          <PlanCityViewMediaBody
            mediaList={mediaList}
            cityPopulation={cityPopulation}
            campaignMediaSites={campaignMediaSites}
            setSelectedMediaSites={setSelectedMediaSites}
          />
        </tbody>
      </table>
    </div>
  );
}

/**
 * Page
 */
function CampaignPlanCityviewPage() {
  const { campaignId: id, cityId } = useParams();

  // State
  const [selectedMediaSites, setSelectedMediaSites] = useState([]);
  const [filteredMediaList, setfilteredMediaList] = useState([]);

  // Selector State
  const citySummaryLoading = useSelector(
    (state) => state.campaignCityReport.citySummaryLoading
  );
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );

  const { targetGroupId = "", budget = 0, spend = 0 } = campaign;

  const { population: cityPopulation } = citySummary;

  // CityMedia and Data
  const cityMediaLoading = useSelector(
    (state) => state.campaignMedia.cityLoading
  );
  const cityMediaSummary = useSelector(
    (state) => state.campaignMedia.citySummary
  );
  const cityMediaSummaryResult = cityMediaSummary.result || {};
  const { items = [] } = cityMediaSummaryResult;

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );

  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // loader for adding media sites to campaign
  const selectedSitesLoading = useSelector(
    (state) => state.exploreSitesSelection.selectedSitesLoading
  );

  //list
  const mediaList = useSelector(
    (state) => state.mapViewExploreSites.exploreSites
  );

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignPlanOverviewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignPlanCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  const cityInfo = {
    cityId: cityId,
    cityName: citySummary?.cityName || cityId,
  };

  const pageNumber = defaultPagination.pageNo;
  const pageSize = defaultPagination.pageSizeMax;

  //Dispatch
  const dispatch = useDispatch();

  const isOrgMedia = true;

  // useEffect
  useEffect(() => {
    setSelectedMediaSites([]);
    dispatch(getMapViewExploreSites(cityId, isOrgMedia, pageNumber, pageSize));
    dispatch(getCityOverview(id, cityId));
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize));
  }, [dispatch, id, cityId]);

  useEffect(() => {
    dispatch(getCityOverview(id, cityId));
    setSelectedMediaSites([]);
  }, [items.length]);

  // link to city-specific-Map-view
  const campaignPlanMapViewPageUrl = RedirectTo.campaignPlanMapViewPageUrl
    .replace(":campaignId", id)
    .replace(":cityId", cityId);

  const pageTitle = campaign.title + " :: " + cityInfo.cityName;
  const pageHeaderAction = (
    <>
      <button
        className="btn btn-primary rounded-lg px-3 mr-3 shadow-none"
        onClick={handleMediaSelection}
      >
        Add to campaign
        {selectedSitesLoading && (
          <Spinner className="ml-2 spinner-border-sm " />
        )}
      </button>
      <Link
        className="btn btn-primary rounded-lg px-3"
        to={{
          pathname: constructRedirectPath(campaignPlanMapViewPageUrl),
          state: { targetGroupId },
        }}
      >
        Select Media on Map
      </Link>
    </>
  );

  //checks for page loading
  if (citySummaryLoading || cityMediaLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  function handleMediaSelection() {
    if (selectedMediaSites.length > 0) {
      dispatch(selectExploreSite(id, selectedMediaSites));
    }
  }

  // This is passed in "ConfirmAction Form" to Remove selected media-site
  function removeMediaSiteFn() {
    dispatch(removeMediaSite(id, processDataObject.campaignMediaInfo));
  }

  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={cityInfo}
        redirectLinkObj={redirectLinkObj}
      />

      {/* Main Content */}
      <div className="content-wrapper">
        {/* Page Header */}
        <PageHeader title={pageTitle} actions={pageHeaderAction} />

        {/* Page Content */}
        <div className="page-content">
          {/* Performance Card */}
          <CampaignCityPerformance
            citySummary={citySummary}
            budget={budget}
            spend={spend}
          />

          <SearchFilterAndSortMediaSection
            mediaList={mediaList}
            setExploreSitesList={setfilteredMediaList}
            listingViewLabels={{
              search: {
                placeholder: "Search media sites from inventory",
                fields: ["title"],
              },
            }}
            isOrgMedia={isOrgMedia}
          />

          {/* Media Table */}
          <PlanCityViewMediaTable
            mediaList={filteredMediaList}
            cityPopulation={cityPopulation}
            campaignMediaSites={items}
            setSelectedMediaSites={setSelectedMediaSites}
          />

          {/** Modals */}
          {openConfirmationForm && (
            <ConfirmationForm onClick={removeMediaSiteFn} />
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignPlanCityviewPage;
